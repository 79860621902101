<template>
  <div class="login auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 divvy-form__container">
      <!-- Brand logo-->
      <b-link :to="{ name: 'site-home' }" class="brand-logo">
        <h2 class="ml-4 pb-5">
          <b-img class="logo-size" :src="imgLogo" alt="Divvy" />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Login-->
      <b-col lg="6" class="align-items-center auth-bg px-2 p-lg-5 mt-5">
        <b-col sm="6" md="6" lg="12" class="px-xl-2 mx-auto">
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="divvy-form__form auth-login-form mt-2"
              @submit.prevent="loginUser">
              <h1 class="divvy-login__title_adm">Faça seu login</h1>

              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider name="Email" rules="required|email">
                  <b-form-input
                    id="login-email"
                    v-model="login.userNameOrEmailAddress"
                    :state="errors.length > 0 ? false : null"
                    name="email"
                    placeholder="seuemail@exemplo.com.br" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Senha</label>
                </div>
                <validation-provider name="Senha" rules="required">
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="login-password"
                      v-model="login.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group class="mb-2">
                <div class="d-flex justify-content-between">
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1">
                    Lembre de mim
                  </b-form-checkbox>

                  <b-link
                    :to="{ name: 'recuperar-senha' }"
                    class="forgot-password">
                    <small>Esqueceu sua senha?</small>
                  </b-link>
                </div>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm">
                Entrar
              </b-button>
            </b-form>
          </validation-observer>

          <!-- divider -->
          <div class="divider my-2 mt-3 mb-3">
            <div class="divider-text">Ou continuar com</div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-link
              class="icon-background-color-white"
              href="javascript:void(0)">
              <b-img
                class="logo-size"
                :src="require('@/assets/images/icons/social/icon_gmail.png')"
                alt="Gmail" 
                @click="loginGoogle"/>
            </b-link>
            <b-link
              class="icon-background-color-white"
              href="javascript:void(0)">
              <b-img
                class="logo-size"
                :src="require('@/assets/images/icons/social/icon_facebook.png')"
                alt="Gmail" 
                @click="logInWithFacebook"/>
            </b-link>
            <b-link
              class="icon-background-color-white"
              href="javascript:void(0)">
              <b-img
                class="logo-size"
                :src="require('@/assets/images/icons/social/icont_witter.png')"
                alt="Gmail" />
            </b-link>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->

      <!-- Left Text-->
      <background-card />

      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BackgroundCard from '../template/BackgroundCardAdm.vue'
import menu from '../menu/menu'
import VueGapi from 'vue-gapi'
import Vue from 'vue'

Vue.use(VueGapi, {
  apiKey: Vue.prototype.gapiKey,
  clientId: Vue.prototype.gapiClientId,
  scope: 'openid profile email',
  plugin_name:'Divvy'
})

const FB_ID = Vue.prototype.fbId

const LoginWithTwitter = require('login-with-twitter')

const tw = new LoginWithTwitter({
  consumerKey: Vue.prototype.twitterKey,
  consumerSecret: Vue.prototype.twitterSecret,
  callbackUrl: 'http://localhost:8080/twitter/callback'
})

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BImg,
    BForm,
    BButton,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BackgroundCard,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      currentLogin: null,
      status: '',
      password: '',
      userEmail: '',
      error: '',
      errors: '',
      sideImg: require('@/assets/images/pages/login/img-login.png'),
      imgLogo: require('@/assets/images/logo/logo.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      login: {
        userNameOrEmailAddress: null,
        password: null,
      },
      menu,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('encryptedAccessToken')
    localStorage.removeItem('role')
    localStorage.removeItem('name')
    localStorage.removeItem('menu')
    localStorage.removeItem('jwt')
    localStorage.removeItem('id')
    localStorage.removeItem('quiz')

    
    if (typeof FB === "undefined") {
        this.fbInit()
    } else {
        window.FB.XFBML.parse();
    }
    this.$gapi.getGapiClient()
  },
  methods: {

    loginGoogle() {
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
            this.googleLoginStatusChangeCallback(
                gapi.auth2.getAuthInstance().isSignedIn.get()
            )
        })
    },

    googleLoginStatusChangeCallback(isSignedIn) {
        if (isSignedIn) {
          const model = {}
          model.authProvider = 'Google';
          model.providerAccessCode = gapi.auth2
              .getAuthInstance()
              .currentUser.get()
              .getAuthResponse().access_token;
          model.providerKey = gapi.auth2
              .getAuthInstance()
              .currentUser.get()
              .getBasicProfile()
              .getId()
          this.loginExternal(model)
        }
    },

    validationForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          return true
        }
      })
    },    

    logInWithFacebook() {
      let cc = this
      FB.login((response) => {
        if (response.authResponse) { 
          const auth = response.authResponse
          const model = {
            authProvider: 'Facebook',
            providerKey: auth.userID,
            providerAccessCode: auth.accessToken,
            singleSignIn: false,
          }
          cc.loginExternal(model)
        } else {
          this.logInWithFacebook()
        }
      })
    return false;
  },

    fbInit() {
        window.fbAsyncInit = function() {
            FB.init({
                appId: FB_ID,
                autoLogAppEvents: true,
                xfbml: true,
                version: "v8.0"
            });
            FB.AppEvents.logPageView();
        };
        (function(d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "//connect.facebook.net/fi_FI/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    },
    
    async loginUser() {
      try {
        const response = await this.$http.post(
          '/api/TokenAuth/AuthenticateAdm',
          this.login,
          { validateStatus: () => true },
        )
        this.makeLogin(response)
      } catch (error) {
        this.error = error.message
        this.msgError()
      }
    },

    async makeLogin(response) {      
        if (response.data.error) {
          this.error = response.data.error.message
          this.msgError()
          return
        }
        this.error = ''

        const { userId } = response.data.result
        const { accessToken } = response.data.result
        const { refreshToken } = response.data.result
        const { encryptedAccessToken } = response.data.result

        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)
        localStorage.setItem('encryptedAccessToken', encryptedAccessToken)
        localStorage.setItem('id', userId)

        const current = await this.getResponseCurrentLogin().then(r =>
          JSON.parse(JSON.stringify(r)))
        const roles = current.data.result.user.roles
        if (!roles.includes('Admin') && !roles.includes('MARKETING')) {
          this.error = 'Área restrita para somente perfis Administrativo.'
          this.msgError()
          return
        }
        const rName = current.data.result.user.name
        const nameToUpper = rName[0].toUpperCase() + rName.substr(1)
        localStorage.setItem('name', nameToUpper)
        
        let rRole = ''
        if(roles.includes('Admin'))
          rRole = 'Admin'
        else {
          rRole = 'MARKETING'
        }
        const roleToUpper = rRole[0].toUpperCase() + rRole.substr(1)
        localStorage.setItem('role', roleToUpper)

        if (current.data.result.user.roles.includes('MARKETING')) {
          localStorage.setItem('menu', JSON.stringify(this.menu.marketing))
        } else {
          localStorage.setItem('menu', JSON.stringify(this.menu.admin))
        }
        localStorage.setItem('color', '#F8AC18')
        localStorage.setItem('color-bg', '#fff7e8')
        document.documentElement.style.setProperty('--color', localStorage.getItem('color'))
        document.documentElement.style.setProperty('--color-bg', localStorage.getItem('color-bg'))

        if (refreshToken) {
          localStorage.setItem('jwt', true)
          this.msgSuccess()
          setTimeout(() => {
            this.$router.push('/dashboard')
          }, 2700)
        } else {
          this.error = response.data.result.error.message
          this.msgError()
        }
    },

     async loginExternal(model){  
        try {
        const response = await this.$http.post(
          '/api/TokenAuth/AuthenticateExternalAdm',
          model,
          { validateStatus: () => true },
        )
         if (response.data.error) {
          if(response.data.error.message === 'NOT_FOUND'){
            this.redirectToRegister(model)
          } else {
            this.error = response.data.error.message
            this.msgError()
          }
          return
        } else {
          this.makeLogin(response)
        }
      } catch (error) {
      }
    },

    async getResponseCurrentLogin() {
      const response = await this.$http.get(
        '/api/services/app/Session/GetCurrentLoginInformations',
        {
          headers: {
            accept: 'application/json',
            'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
          },
        },
      )
      return response
    },
    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Login efetuado com sucesso',
        text: 'Você será redirecionado para área administrativa',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao efetuar o login',
        text: this.error,
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss">
@import '../sass/variables.scss';
@import '../sass/_login.scss';
@import '../sass/divvy-form.scss';

body {
  background-color: #fff !important;
}
.icon-background-color-white {
  background-color: #fff !important;
  margin-right: 20px;
}
.logo-size {
  width: 40px;
}

a.forgot-password {
  color: #5e5873;
}

.login__form {
  max-width: 100% !important;
}

.divider {
  max-width: 80%;
}
@import '@core/scss/vue/pages/page-auth.scss';

</style>
