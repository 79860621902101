<template>
  <b-col lg="6" md="6" class="p-5 background-card">
    <div id="page-divider" />
    <div class="background-card__text-container">
      <h1 class="background-card__title">
        Área Administrativa
      </h1>
    </div>
  </b-col>
</template>

<script>
import { BCol } from 'bootstrap-vue'

export default {
  components: {
    BCol,
  },
}
</script>

<style lang="scss">
@import '../sass/_background-card.scss';
</style>
